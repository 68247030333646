
import React, {Component} from 'react';
import Info from './Info';
import Details from './Details';
import Screenshots from './Screenshots';


class MainApp extends Component {

	constructor(props) {
		super(props);

		this.state = {	
			loggedIn: false
		};
	
		
	}


	componentDidMount() {}

	render() {
		return (
            <div id="mainDiv">								
                <Info />	
                <Details />
                <Screenshots />

				<footer className="footer">
					<div className="footer_container">			
							
						<p className="text_st">
							<a target={'_blank'} rel="noreferrer" href={"http://policies.workoutsjournal.com/policy/"}>Privacy Policy</a> |
							<a target={'_blank'} rel="noreferrer" href={"http://policies.workoutsjournal.com/terms/"}>Terms of Service</a> |
							Copyright 2023 Workouts Journal.
						</p>

					</div>
				</footer>

            </div>

        )
		
		
	}
}


export default MainApp; //Jotta app osaa importtaa tämän

