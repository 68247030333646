
import React, {Component} from 'react';
import PasswordStrengthMeter  from './passwordStrengthMeter';


class Login extends Component{
  
    constructor(props) {
        
		super(props);
        this.state = {newuser: false, errorText: "", password: '' };	
        this.handleLogin = this.handleLogin.bind(this);
        this.handleNewUser = this.handleNewUser.bind(this);
        this.username = null;
        this.password = null;
        this.newUsername = null;
        this.newPassword = null;
        this.newPassword2 = null;

        this.setUsername = element => { this.username = element; };
        this.setPassword = element => { this.password = element; };
        this.setnewUsername = element => { this.newUsername = element; };
        this.setnewPassword = element => { this.newPassword = element; };
        this.setnewPassword2 = element => { this.newPassword2 = element; };
    } 


    handleNewUser(e){
        e.preventDefault();

        this.newUsername.style="";
        this.newPassword.style="";
        this.newPassword2.style="";

        if(this.newUsername.value === "" && this.newPassword.value === "" && this.newPassword2.value === ""){
            this.newUsername.style="border-color:red";
            this.newPassword.style="border-color:red";
            this.newPassword2.style="border-color:red";

            this.setState({
                errorText: "Syötä kenttien tiedot!"
            });
            return;
        }
        else if(this.newUsername.value === "" && this.newPassword.value !== "" && this.newPassword2.value !== ""){
            this.newUsername.style="border-color:red";

            this.setState({
                errorText: "Käyttäjätunnus puuttuu!"
            });
            return;
        }
        else if(this.newUsername.value === "" && this.newPassword.value === "" && this.newPassword2.value !== ""){
            this.newUsername.style="border-color:red";
            this.newPassword.style="border-color:red";
            
            this.setState({
                errorText: "Syötä kenttien tiedot!"
            });
            return;
        }
        else if(this.newUsername.value === "" && this.newPassword.value !== "" && this.newPassword2.value === ""){
            this.newUsername.style="border-color:red";
            this.newPassword2.style="border-color:red";
            
            this.setState({
                errorText: "Syötä kenttien tiedot!"
            });
            return;
        }
        else if(this.newUsername.value !== "" && this.newPassword.value === "" && this.newPassword2.value === ""){
            this.newPassword2.style="border-color:red";
            this.newPassword.style="border-color:red";
            
            this.setState({
                errorText: "Syötä salasanat!"
            });
            return;
        }
        else if(this.newUsername.value !== "" && this.newPassword.value === "" && this.newPassword2.value !== ""){
            this.newPassword.style="border-color:red";
            
            this.setState({
                errorText: "Syötä salasana!"
            });
            return;
        }
        else if(this.newUsername.value !== "" && this.newPassword.value !== "" && this.newPassword2.value === ""){
            this.newPassword2.style="border-color:red";
            
            this.setState({
                errorText: "Syötä salasana!"
            });
            return;
        }
        else if(this.newPassword.value !== this.newPassword2.value){
            this.setState({
                errorText: "Salasanat eivät täsmää!"
            });
            return;
        }

        fetch("/api/createNewUser", {
            "method": "POST",
            "headers": {
                'Content-Type': 'application/json',
                "accept": "application/json"
            },
            "body": JSON.stringify({
                username: this.newUsername.value,
                password: this.newPassword.value
            })
        })
        .then(response => response.json())
        .then(response => {

            if(response.error){
                this.newUsername.style="border-color:red";
                this.setState({
                    errorText: "Käyttäjätunnus on jo käytössä!"
                });
            }
            else{
                this.newUsername.value = '';
                this.newPassword.value = '';
                this.newPassword2.value = '';

                this.setState({
                    newuser: false,
                    errorText: "",
                    password: ""
                });
            }

        })
        .catch(err => { console.log(err); 
        });

    }


    handleLogin(e){

        this.username.style="";
        this.password.style="";

        e.preventDefault();
        if(this.username.value === "" && this.password.value === ""){
            this.username.style="border-color:red";
            this.password.style="border-color:red";
            this.setState({
                errorText: "Syötä käyttäjätunnus ja salasana!"
            });
            return;
        }
        else if(this.username.value === ""){
            this.username.style="border-color:red";
            this.setState({
                errorText: "Käyttäjätunnus ei voi olla tyhjä!"
            });
            return;
        } 
        else if(this.password.value === ""){
            this.password.style="border-color:red";
            this.setState({
                errorText: "Salasana ei voi olla tyhjä!"
            });
            return;
        }
        
        fetch("/api/getUser", {
            "method": "POST",
            "headers": {
                'Content-Type': 'application/json',
                "accept": "application/json"
            },
            "body": JSON.stringify({
                username: this.username.value,
                password: this.password.value
            })
        }) 
        .then(response => response.json())
        .then(response => {

            if(response.error){
                this.setState({
                    errorText: "Väärä käyttäjätunnus ja/tai salasana!"
                });
            }
            else{
                this.props.login(response);
            }
        })
        .catch(err => { console.log(err); });



    }
    
    render() { 

        
	/*	background: rgb(228,248,249);  #E4F8F9
  background: linear-gradient(90deg, rgba(228,248,249,1) 0%, rgba(217,240,240,1) 54%, rgba(224,244,246,1) 100%);
   background-image: url("login5.jpg") !important;
	background-size: 100% 100% !important;

*/
     //   document.getElementById('canvas').style.display = '';

        document.getElementsByTagName("body")[0].style.backgroundImage = 'url("../public/images/login.jpg")';
		document.getElementsByTagName("body")[0].style.backgroundSize = '100% 100%';
		document.getElementsByTagName("body")[0].style.background = '';
		

        const newUser = (status, view) => e => {
            e.preventDefault();
            if(view === 1){
                this.username.value = '';
                this.password.value = '';
            }
            else if(view === 2){
                this.newUsername.value = '';
                this.newPassword.value = '';
                this.newPassword2.value = '';
            }
            
            this.setState({
                newuser: status,
                errorText: "",
                password: ""
            });


        }; 

        if(!this.state.newuser){
            return (
                <div className="loginform">

                        <div>

                            <h2>Log in</h2>

                            <p>
                                Testiä varten on tehty käyttäjä demo salasanalla pass.
                            </p>
                            <form>
                                <span className="errorText">{this.state.errorText}</span>
                                <p>
                                    <input title="Syötä tähän käyttäjätunnuksesi" ref={this.setUsername} type="text" placeholder="Käyttäjätunnus" className="field"/>
                                </p>
                                <p>
                                    <input title="Syötä tähän salasanasi" ref={this.setPassword} type="password" placeholder="Salasana" className="field"/>                                    
                                </p>
                                <div>
                                    <button className="loginbtn pointer" onClick={this.handleLogin}>Kirjaudu sisään</button>
                                    <a href="/#" onClick={newUser(true, 1)} className="newusertxt">Uusi käyttäjä?</a>
                                </div>
                            </form>
                        </div>
                </div>
            )
        }
        else if(this.state.newuser){
            return (
                <div className="loginform">
                        <div>
                            <h2>Luo käyttäjä</h2>

                            <form>
                                <span className="errorText">{this.state.errorText}</span>
                                <p>
                                    <input autoComplete="off" title="Syötä tähän uusi käyttäjätunnus" ref={this.setnewUsername} type="text" placeholder="Anna käyttäjätunnus" className="field"/>
                                </p>
                                <p className="passbottom">
                                    <input  autoComplete="new-password" title="Syötä tähän salasanasi" ref={this.setnewPassword} onChange={e => this.setState({ password: e.target.value })} type="password" placeholder="Anna salasana"  className="field"/>
                                </p>
                                <PasswordStrengthMeter password={this.state.password} />
                                <p>
                                    <input  autoComplete="new-password" title="Syötä tähän sama salasana kuin ylhäällä" ref={this.setnewPassword2} type="password" placeholder="Anna salasana uudestaan" className="field"/>
                                </p>
                                <div>
                                    <button className="loginbtn" onClick={this.handleNewUser}>Luo tunnus</button>
                                    <button className="backtologin" onClick={newUser(false, 2)}>Takaisin kirjautumiseen</button>
                                </div>
                            </form>
                        </div>
                </div>
            )
        }
	}
}




export default Login; //Jotta app osaa importtaa tämän