import React, { Component } from "react";
//import { Polyline, Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";

export class StickyNoteMap extends Component {
  state = {
    activeMarker: {},
    selectedPlace: {},
    showingInfoWindow: true
  };
/*
  onMarkerClick = (props, marker) =>
    this.setState({
      activeMarker: marker,
      selectedPlace: props,
      showingInfoWindow: true
    }); 

  onInfoWindowClose = () =>
    this.setState({
      activeMarker: null,
      showingInfoWindow: false
    });

  onMapClicked = () => {
    if (this.state.showingInfoWindow)
      this.setState({
        activeMarker: null,
        showingInfoWindow: false
      });
  };*/

  render() {

  /*  const mapStyles = {
      width: '33rem',
      height: '23.6rem',
    };
*/
    const jind = this.props.jindex;
    const averagespeed = this.props.note.recordedJourneys[jind].averagespeed
    const calories = this.props.note.recordedJourneys[jind].calories
    const duration = this.props.note.recordedJourneys[jind].duration
    const journeylength = this.props.note.recordedJourneys[jind].journeylength


    var content = [];  
    var markers = [];
    var startingLat = "";
    var startingLng = "";

    let coords = this.props.note.recordedJourneys[jind].journeycoords;
    coords = coords.split(":");

    for(let x = 0;x < coords.length;x++){
      
      let splittedCoords = coords[x].split("-");
      let carr = [];
      carr['lat'] = parseFloat(splittedCoords[0]);
      carr['lng'] = parseFloat(splittedCoords[1]);
      content.push(carr);
     
      if(parseInt(x) === 0){
        startingLat = parseFloat(splittedCoords[0]);
        startingLng = parseFloat(splittedCoords[1]);
        markers.push({
          id: 1,
          name: "Aloituspaikka",
          position: { lat:  startingLat, lng: startingLng }
        });
       
      }
      else if(parseInt(x) === coords.length - 1){
        markers.push({
          id: 2,
          name: "Lopetuspaikka",
          position: { lat:  parseFloat(splittedCoords[0]), lng: parseFloat(splittedCoords[1]) }
        });
      }
    }

    if (!this.props.loaded) return <div>Ladataan...</div>;

    return (

      <div className="mapHolderDiv">        
        <div className="journeyInfoDiv">
          <div className="journeyInfo1">
            <p>Keskinopeus: {averagespeed}</p>
            <p>Matkan pituus: {journeylength}</p>
          </div>
          <div className="journeyInfo2">
            <p>Käytetty aika: {duration}</p>
            <p>Kulutetut kalorit: {calories}</p>
          </div>
        </div>
        <div className="mapDiv">
        

        </div>
      </div>
    );
  }
}

export default /*GoogleApiWrapper({
  apiKey: "AIzaSyBKW2wuzuPo1HL2F2957eJ5VHc8XKE7J-A",
  version: "3.38"
})(*/StickyNoteMap; //);

/*  tää mapsDiv kohtaan!
  <Map        
            onClick={this.onMapClicked}
              google={this.props.google}
            zoom={16}
            style={mapStyles}
            initialCenter={{ 
                lat: startingLat, 
                lng: startingLng
            }}>
            
            <Polyline
                path={content}
                geodesic={true}
                options={{
                    strokeColor: "black",
                    strokeOpacity: 1.75,
                    strokeWeight: 3,
                  }}
                  />
                      
                  {markers.map(({ id, name, position }) => (
                    <Marker
                      key={id}
                      position={position}
                      title={name}
                  //   label={name}
                      name={name}
                      onClick={this.onMarkerClick}
                    >
                    </Marker>
                  ))}
      
                  <InfoWindow
                  marker={this.state.activeMarker}
                  onClose={this.onInfoWindowClose}
                  visible={this.state.showingInfoWindow}
                  >
                  <div>
                    <h4>{this.state.selectedPlace.name}</h4>
                  </div>
                  </InfoWindow>
                </Map>

                */
