import React, {Component} from 'react';

class Program extends Component {

    constructor(props) {
        super(props);
        this.state = {color: false}
    }

    handleProgram(){
        this.props.showProgram(this.props.program.id);
    }

    mouseIn(){
        Array.from(document.getElementsByClassName("schecolumn")).forEach(
            function(element, index, array) {
                element.classList.remove("schedulertablehover");
            } 
        );
    }
    mouseOut(){
        Array.from(document.getElementsByClassName("schecolumn")).forEach(
            function(element, index, array) {
                element.classList.add("schedulertablehover");
            }
        );
    } 

    render() {

    //    const Colors = ["lightsalmon", "lightseagreen", "lightgreen", "lightskyblue", "orange", "brown", "lightgrey"];

        var lajiImage = "./images/gym.png";
    //    var lajiColor = "rgb(224, 61, 61)";
        if(parseInt(this.props.program.programLaji) === 1) lajiImage = "./images/runner.png";
        else if(parseInt(this.props.program.programLaji) === 2) lajiImage = "./images/biker.png";
        else if(parseInt(this.props.program.programLaji) === 3) lajiImage = "./images/skier.png";
        else if(parseInt(this.props.program.programLaji) === 5) lajiImage = "./images/stretch.png";
        else if(parseInt(this.props.program.programLaji) === 6) lajiImage = "./images/random.png";


        const getColor = () => {  

            if(parseInt(this.props.program.programLaji) === 1) return "rgb(235, 132, 49)";
            else if(parseInt(this.props.program.programLaji) === 2) return "rgb(56 143 7)";
            else if(parseInt(this.props.program.programLaji) === 3) return "rgb(137 153 161)";
            else if(parseInt(this.props.program.programLaji) === 4) return "rgb(224, 61, 61)";
            else if(parseInt(this.props.program.programLaji) === 5) return "rgb(21 186 253)";
            else if(parseInt(this.props.program.programLaji) === 6) return "rgb(238, 100, 215)";
            else return '#' +  Math.random().toString(16).substr(-6);
          //  return Colors[this.props.dayOfW];
        //  if(this.state.color == false) this.state.color = '#' +  Math.random().toString(16).substr(-6);
      //    return this.state.color;
        };

        let endString = "", startString = "";
        let startT = this.props.program.startTime;
  //      let topPos = 0;
        let classType = "progcontainer";
        let duration = this.props.program.duration * 1.5;
        let endTime = this.props.program.duration * 0.5 + startT;
        if(parseInt(this.props.program.half) === 1){
          //  topPos = 1.5;
            endTime += 0.5;
            startString = startT + ":30";
        }
        else startString = startT + ":00";
        
        if(parseInt(endTime) % 1 !== 0){
            endTime -= 0.5;
            endString = endTime + ":30";
        }
        else endString = endTime + ":00";

        if(parseInt(this.props.dayofStatus) === 3){
            classType = "progcontainer2"
        }
        else if(parseInt(this.props.dayofStatus) === 2){
            classType = "progcontainer3"
        }

        const getSecondText = () => {
            
            if(/*this.props.dayofStatus == 1 &&*/ parseInt(this.props.program.duration) !== 1) return <div className="text2"><div className="overflowtime">{startString+ " - "+ endString}</div></div>;
        }
        
        return(
            <div className={classType} >
                <div onClick={this.handleProgram.bind(this)} onMouseLeave={this.mouseOut.bind(this)} onMouseOver={this.mouseIn.bind(this)}  className="progFocus" style={{height: duration + "rem",  width: "100%", backgroundColor: getColor()}}>
                    <div className="progbox2">
                        <div className="textInfo">
                            <div className="textCont">
                                <div className="text1"><img src={lajiImage} alt="Laji" title="Ohjelman laji" width="15" height="15" className="logout-icon"></img> {this.props.program.program}</div>
                                {getSecondText()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
  
}

export default Program; //Jotta app osaa importtaa tämän