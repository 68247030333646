
import React, {Component} from 'react';

class Screenshots extends Component {

	constructor(props) {
		super(props);

		this.state = {	
			loggedIn: false
		};
	
		
	}


	componentDidMount() {}

	render() {
		return (
            <div className="screencon">
                
               <div className="partcontent">
        
                <h1>Screenshots</h1>

                        <div className="pic-col">
                            <div className="shot_div">
                                <img width={160} src="./images/screen_workout.png" alt="Workout Page"></img>
                                <div className="caption">
                                    <p>See your route in real-time, including your pace, duration, distance and spent calories.</p>
                                </div>
                            </div>
                        </div>

                        <div className="pic-col">
                            <div className="shot_div">
                                <img width={160} src="./images/screen_history.png" alt="History Page"></img>
                                <div className="caption">
                                    <p>Review your workout history. You can also see your monthly statistics here.</p>
                                </div>
                            </div>
                        </div>
                        <div className="pic-col">
                            <div className="shot_div">
                                <img width={160} src="./images/screen_detail.png" alt="Details Page"></img>
                                <div className="caption">
                                    <p>View your workout route on a map. You can also check your workout statistics, including gained experience.</p>
                                </div>
                            </div>
                        </div>

                        <div className="pic-col">
                            <div className="shot_div">
                                <img width={160} src="./images/screen_challenges.png" alt="Challenges Page"></img>
                                <div className="caption">
                                    <p>Check your Daily and Weekly challenges here. Do them actively to gain even more experience.</p>
                                </div>
                            </div>
                        </div>

                        <div className="pic-col pic-col2">
                            <div className="shot_div">
                                <img width={160} src="./images/screen_leaderboard.png" alt="Leaderboard Page"></img>
                                <div className="caption">
                                    <p>View global leaderboard. Workout actively, do your Daily / Weekly challenges to gain experience points and claim your spot on the leaderboard.</p>
                                </div>
                            </div>
                        </div>

                        <div className="pic-col pic-col2">
                            <div className="shot_div">
                                <img width={160} src="./images/screen_login.png" alt="Login Page"></img>
                                <div className="caption">
                                    <p>Create new account or use Google login to make sure you can view your workouts in every phone.</p>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        )
		
		
	}
}


export default Screenshots; 
