import { Routes , Route } from 'react-router-dom'
import App from './App';
import MainApp from './components/mobile/MainApp';

const RoutedApp = () => {

    return (
       <Routes>
            <Route path='/' element={ <MainApp/>}/>
            <Route path='/workouts' element={ <App/>}/>
       </Routes>
    );
}

export default RoutedApp;