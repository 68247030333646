
import React, {Component} from 'react';

class Details extends Component {

	constructor(props) {
		super(props);

		this.state = {	
			loggedIn: false
		};
	
		
	}


	componentDidMount() {}

	render() {
		return (
                <div className="partcontent">
            
                    <h1>Benefits</h1>
                    <ul>
                        <li>Track your workout route in real-time.</li>
                        <li>See your workout spent time, speed, pace and burned calories in real-time as you run.</li>
                        <li>History of all recorded workouts.</li>
                        <li>Monthly statistics from your workouts. See your monthly burned calories, spent time, distance and gained experience.</li>
                        <li>View your used route from all of your workouts.</li>
                        <li>Daily and Weekly challenges. Stay motivated and do your challenges to gain even more experience points.</li>
                        <li>Gain experience points and level up from your workouts.</li>
                        <li>Compete on the leaderboard.</li>
                        <li>No Ads.</li>
                    </ul>
                </div>
        )


	}
}


export default Details; 
