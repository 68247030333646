import React from 'react';
import ReactDOM from 'react-dom/client';
import './main.css';
import RoutedApp from './RoutedApp';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
 
/*
const root = ReactDOM.createRoot(document.getElementById('root'));
console.log("index.js");
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);*/

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <RoutedApp />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
