
import React, {Component} from 'react';
import ContentEditable from 'react-contenteditable'

class StickyNoteProgram extends Component {

    constructor(props) {
        super(props);
        this.handleTitleChange = this.handleTitleChange.bind(this);
        this.handleContentChange = this.handleContentChange.bind(this);
        this.handleSaveEdits = this.handleSaveEdits.bind(this);
        this.handleDeleteProgram = this.handleDeleteProgram.bind(this);
        this.handleShowVideo = this.handleShowVideo.bind(this);    
        this.handleAddVideo = this.handleAddVideo.bind(this);    
        this.handleDeleteVideo = this.handleDeleteVideo.bind(this);       
        this.titleEditable = React.createRef();
        this.contentEditable = React.createRef();
        this.timeDiv = null;
        this.butDiv = null;
        this.contDiv = null; 
        this.setTimeDiv = element => { this.timeDiv = element; };
        this.setButDiv = element => { this.butDiv = element; };
        this.setContDiv = element => { this.contDiv = element; };
        let titleText = '<span style="color: black; font-size: 1.2rem; ">' + this.props.note.program+'</span>';
        let contentText = '<span>' + this.props.note.subject+'</span>';
        this.state = {title: titleText,
                        titleText: this.props.note.program,
                        content: contentText,
                        contentText : this.props.note.subject,
                        small: false
                     }; 
    } 

    handleTitleChange(evt){
        this.setState({title: evt.target.value,
                       titleText: evt.currentTarget.innerText});
    }
    handleContentChange(evt)  {
        this.setState({content: evt.target.value,
                       contentText: evt.currentTarget.innerText});
    }
    handleSaveEdits(e){
        e.preventDefault();
        var cont = this.state.contentText.trim();
        var titlet = this.state.titleText.trim();
        if(cont === "" || titlet === ""){
            alert("Ohjelman otsikko tai aihe eivät voi olla tyhjiä");
            return;
        }
        this.props.saveEdits(this.props.note.id, this.state.titleText, this.state.contentText);
    }
    handleDeleteProgram(e){
        e.preventDefault();
        if (window.confirm("Haluatko varmasti poistaa ohjelman?")) {
            this.props.deleteProgram(this.props.note.id);
        }
        
    }

    handleShowVideo(e){
        this.props.showVideo(this.props.note.program_video.videoId);
    }    

    handleAddVideo(e){
        this.props.addVideo(this.props.note.id);
    }

    handleDeleteVideo(e){
        if (window.confirm("Haluatko varmasti poistaa videon?")) {
            this.props.deleteVideo(this.props.note.program_video.id, this.props.note.id);
        }
       
    }
   
    render() {

        let contentsize = "contents";

        let endString = "", startString = "";
        let startT = this.props.note.startTime;
    //    let height = (startT - 6) * 3;
        
      //  let duration = this.props.note.duration * 1.5;
        let endTime = this.props.note.duration * 0.5 + startT;
        if(parseInt(this.props.note.half) === 1){
     //       height += 1.5;
            endTime += 0.5;
            startString = startT + ":30";
        }
        else startString = startT + ":00";
        
        if(endTime % 1 !== 0){
            endTime -= 0.5;
            endString = endTime + ":30";
        }
        else endString = endTime + ":00";
       
        const repeatString = () => {

            if(parseInt(this.props.note.repeatance) === 1) return "Kertaohjelma";
            else if(parseInt(this.props.note.repeatance) === 2) return "Päivittäinen";
            else if(parseInt(this.props.note.repeatance) === 3) return "Viikottainen";
            else if(parseInt(this.props.note.repeatance) === 4) return "Kuukausittainen";
       
        }
 
        const videoButtons = () => {
            
            if(this.props.note.program_video == null || this.props.note.program_video.id == null){
               
                return (
                    <button title="Lisää ohjelmaan video" className="pointer btnyoutube btnwidth" onClick={this.handleAddVideo}>Lisää video</button>
                )
               
            }
            else if(this.props.note.program_video != null){
                return (
                    <>
                        <button title="Katso video" className="pointer btnyoutube btnbmtmar btnbmtmar btnwidth" onClick={this.handleShowVideo}>Katso video</button>
                        <button title="Poista video ohjelmasta video" className="pointer btnyoutube btnwidth" onClick={this.handleDeleteVideo}>Poista video</button>
                    </>
                )
            }
            
        }
 
        const generateInfo = () => {

            return(
                <div className="programDataBtnsResult" ref={this.setButDiv}>
                    <div className="programDataTallenna">
                        <button title="Tallenna tehdyt muutokset ohjelmaan" className="saveProg pointer btnbmtmar btnwidth" onClick={this.handleSaveEdits}>Tallenna</button>
                        <button title="Poista tämä ohjelma kalenterista" className="pointer pohbtn btnwidth" onClick={this.handleDeleteProgram}>Poista ohjelma</button>
                    </div>
                    <div className="programDataOther">                            
                        {videoButtons()}
                    </div>
                </div>
            )
        };
     
        return (
            <div key={this.props.note.id} className="program-note" >
                <div className="handle2">
                    <div className="noteTitle">
                        <ContentEditable
                         innerRef={this.titleEditable}
                        html={this.state.title} 
                        disabled={false}      
                        onChange={this.handleTitleChange} 
                        tagName='article'
                    />
                    </div>
                </div>
                <div className={contentsize} ref={this.setContDiv}>
                    <ContentEditable
                        innerRef={this.contentEditable}
                        html={this.state.content} 
                        disabled={false}      
                        onChange={this.handleContentChange} 
                        tagName='article'
                    />
                </div>
                <div>
                    <div className="programData" ref={this.setTimeDiv}>
                        <p className="datespan">{startString+ " - "+ endString}</p>
                        <p className="datespan">{this.props.formattedDateS}</p>
                        <p className="datespan">{repeatString()}</p> 
                    </div>

                        {generateInfo()}
                        
                    
                </div>
            </div>
        )    
    }
}

export default StickyNoteProgram; //Jotta app osaa importtaa tämän