
import React, {Component} from 'react';

class Info extends Component {

	constructor(props) {
		super(props);

		this.state = {	
			loggedIn: false
		};
	
		
	}


	componentDidMount() {}

	render() {
		return (
            
            <div className="maincon">
                
                <div className="partcontent main_tran">
            
                    <div className="container">
                        <div className="trans_div ">
                            <h1 className="pich">Workouts Journal</h1>
                            <p className="picp"> 
                                GPS tracking app for your workout activities.  
                                Earn experience points from your workouts and climb higher in the leaderboard. Trying to make it to the top makes it easier to stay motivated.
                            </p>
                            <a href='https://play.google.com/store/apps/details?id=com.workouts.workoutsjournal&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img className="googlelogoim" alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
                        </div>
                    </div>
                </div>
            </div>
        )
		
		
	}
}

export default Info; 
