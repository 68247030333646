import React, {useEffect, useRef, useState } from 'react';
import moment from 'moment';
import StickyNoteProgram from './StickyNoteProgram';
import StickyNoteResults from './StickyNoteResults';
import StickyNoteMap from './StickyNoteMap';
import YoutubeVideo from "./youtubeVideo";

//Uudempi react versio (hooks yms)
function ProgramResults (props) {

    const [year, setYear] = useState(() => {
        const dat = new Date();
        return dat.getFullYear();
    });

    const [week, setWeek] = useState(() => {
        return moment(new Date()).isoWeek();
    });
    const [day, setDay] = useState(() => {
        return moment(new Date()).day();
    });

    const [laji, setLaji] = useState(() => {
        return 99;
    });

    const [dayPrograms, setPrograms] = useState(() => {
        return [];
    });

    const [showYoutube, setYouTubeShow] = useState(() => {
        return false;
    });

    const [selectedProgram, setSelectedProgram] = useState(() => {
        return false;
    });

    const refs  = useRef([]);
    const refsdays  = useRef([]);
    const refslajit  = useRef([]);
    const showyoutubeDiv = useRef();
    const showyoutubeAddDiv = useRef();
    const youtubeIDinInput = useRef();
    const youtubeIDSpan = useRef();

    useEffect( (e) => {

        loadPrograms();

        return () => {
          
        };
        // eslint-disable-next-line
    }, [year, day, week, laji,showYoutube, selectedProgram]);  //Updeitataan näkymä aina kun dropareissa tapahtuu muutoksia (Eli ladataan useeffect metodi)

    const loadPrograms = function () {
 
        var isoday;
        if(parseInt(day) !== 0) isoday = day - 1;
        else isoday = 6;
        var selectedDay = moment(year + "-01-01", "YYYY-MM-DD").add(week, 'weeks').startOf('isoweek').add(isoday, 'days').format('YYYY-MM-DD');

        fetch("/api/getUserProgramsAndResultsWithDates/"+selectedDay+"/"+selectedDay+"/"+props.user.id+"/"+laji, {
            method: 'get'
        })
        .then(userProgs => userProgs.json())
        .then(userProgs => {
            setPrograms(userProgs);
        })
        .catch(err => { console.log(err); 
        });

    }

    const deleteProgram = function (id) {

        fetch("/api/delProg", {
            "method": "DELETE",
            "headers": {
                'Content-Type': 'application/json',
                "accept": "application/json"
            },
            "body": JSON.stringify({
                id: id
            })
        })
        .then(response => response.json())
        .then(response => {
            if(response.error) alert("Error occured when deleting program");
            else loadPrograms();
        })
        .catch(err => { console.log(err); });
            
    }

    const saveResults = function (id, content){

        content = content.replace(/\//g, "ffslashhh"); //Requestia varten. Otejaan javassa kiinni takas
        content = content.replace(/\?/g, "ffquestionn"); //Requestia varten. Otejaan javassa kiinni takas

        var isoday;
        if(parseInt(day) !== 0) isoday = day - 1;
        else isoday = 6;
        var selectedDay = moment(year + "-01-01").add(week, 'weeks').startOf('isoweek').add(isoday, 'days').format('YYYY-MM-DD');

        if(content === "") content = "nonee";

        fetch("/api/delResultsByDate", {
            "method": "DELETE",
            "headers": {
                'Content-Type': 'application/json',
                "accept": "application/json"
            },
            "body": JSON.stringify({
                id: id,
                day: selectedDay
            })
        })
        .then(delRes => delRes.json())
        .then(delRes => {

            if(delRes.error){
                alert(delRes.message);
                loadPrograms();
            }             
            else{
                
                fetch("/api/postResult",  {
                    "method": "POST",
                    "headers": {
                        'Content-Type': 'application/json',
                        "accept": "application/json"
                    },
                    "body": JSON.stringify({
                        id: id,
                        day: selectedDay,
                        content:content,
                        set:"",
                        move:"",
                        rep:"",
                        weight:"",
                        row:"",
                    })
                })
                .then(postRes => postRes.json())
                .then(postRes => {
    
                    if(postRes.error){
                        alert(postRes.message);
                        loadPrograms();
                    }
                    else loadPrograms();

                })
                .catch(err => { console.log(err); 
                }); 
            }               
        })
        .catch(err => { console.log(err); this.loadPrograms(); 
        });

    }

    const saveEdits = function (id, title, content) {
    
        fetch("/api/saveEdits", {
            "method": "PUT",
            "headers": {
                'Content-Type': 'application/json',
                "accept": "application/json"
            },
            "body": JSON.stringify({
                id: id,
                title: title,
                content: content
            })
        })
        .then(response => response.json())
        .then(response => {

            if(response.error){
                alert("Failed to update program");
            }
            else{
                loadPrograms();
            }
        })
        .catch(err => { console.log(err); });

    }

    const changeYear = function (e) {
        setYear(e.target.value);
    }

    const changeWeek = function (e) {
        setWeek(e.target.value);
    }

    const changeDay = function (e) {
        setDay(e.target.value);
    }

    const hideYoutube = function (e) {

        showyoutubeDiv.current.style.opacity = "0";
        showyoutubeDiv.current.style.pointerEvents = "none";

        setYouTubeShow(false);
    }

    const hideYoutubeAdd = function (e) {

        showyoutubeAddDiv.current.style.opacity = "0";
        showyoutubeAddDiv.current.style.pointerEvents = "none";
    }

    const handleVideoSubmit = function (e) {

      e.preventDefault();
        var programID = selectedProgram;
        var val = youtubeIDinInput.current.value.trim();
        if(val === ""){
			youtubeIDSpan.current.style="color:red";
			return;
		} 
        else youtubeIDSpan.current.style="color: black";

        fetch("/api/postVideo", {
            "method": "POST",
            "headers": {
                'Content-Type': 'application/json',
                "accept": "application/json"
            },
            "body": JSON.stringify({
                id: programID,
                val: val
            })
        })
        .then(response => response.json())
        .then(response => {

            if(response.error){
                alert(response.message);
            }
            else{
                youtubeIDinInput.current.value = "";			
                showyoutubeAddDiv.current.style.opacity = "0";
                showyoutubeAddDiv.current.style.pointerEvents = "none";
                loadPrograms();
            }
        })
        .catch(err => { console.log(err); });

    }

    const changeLaji = function (e) {
        setLaji(e.target.value);
    }

    const deleteVideo = function (videoID, noteID) {

        fetch("/api/delVideo", {
            "method": "DELETE",
            "headers": {
                'Content-Type': 'application/json',
                "accept": "application/json"
            },
            "body": JSON.stringify({
                id: videoID
            })
        })
        .then(response => response.json())
        .then(response => {
            if(response.error) alert("Error occured when deleting video");
            else  loadPrograms();
        })
        .catch(err => { console.log(err); });
       
    }

    const addVideo = function (id) {

        showyoutubeAddDiv.current.style.opacity = "1";
        showyoutubeAddDiv.current.style.pointerEvents = "auto";

        setSelectedProgram(id);
    }
    
    const showVideo = function (videoid) {

        showyoutubeDiv.current.style.opacity = "1";
        showyoutubeDiv.current.style.pointerEvents = "auto";

        setYouTubeShow(videoid);
    }
 
    const programs = () => {

        if(dayPrograms.length > 0){
        
            let content = [];  
            
            for (let i = 0; i < dayPrograms[1].length; i++) {
                if(dayPrograms[1][i].length > 0){

                    var resultText = "";
                    var isoday;
                //    var resID = "";
                    if(parseInt(day) !== 0) isoday = day - 1;
                    else isoday = 6; 
                    var selectedDay = moment(year + "-01-01").add(week, 'weeks').startOf('isoweek').add(isoday, 'days').format('YYYY-MM-DD');

                    for(let x = 0; x < dayPrograms[0][i].program_results.length; x++){
                        if(dayPrograms[0][i].program_results[x].resultDate === selectedDay){
                            resultText = dayPrograms[0][i].program_results[x].results;
                  //          resID = dayPrograms[0][i].program_results[x].results.id;
                        }
                    }

                    var selectedLaji = "Kuntosali";
                    var selectedColor = "progheaderInfo kuntosaliclass";
                    if(parseInt(dayPrograms[0][i].programLaji) === 1){ selectedLaji = "Juoksu"; selectedColor = "progheaderInfo juoksuclass"; } 
                    else if(parseInt(dayPrograms[0][i].programLaji) === 2){ selectedLaji = "Pyöräily"; selectedColor = "progheaderInfo pyorclass"; } 
                    else if(parseInt(dayPrograms[0][i].programLaji) === 3){ selectedLaji = "Hiihto"; selectedColor = "progheaderInfo hiihtoclass"; } 
                    else if(parseInt(dayPrograms[0][i].programLaji) === 5){ selectedLaji = "Venyttely"; selectedColor = "progheaderInfo stretchclass"; } 
                    else if(parseInt(dayPrograms[0][i].programLaji) === 6){ selectedLaji = "Muu"; selectedColor = "progheaderInfo muuclass"; } 
                   
                    var dateS = selectedDay.split("-");
                    var formattedDateS = dateS[2]+"."+dateS[1]+"."+dateS[0];

                    content.push(<tr key={"trlaji" + i}><td key={"laji"+dayPrograms[0][i].id} colSpan="2" className={selectedColor}>{selectedLaji}  {formattedDateS}</td></tr>)

                    var pHasRecordedJourney = false;
                    var journeyIndex = 0;
                    for (let e = 0; e < dayPrograms[0][i].recorded_journeys.length; e++) {
                        if(dayPrograms[0][i].recorded_journeys[e].jprogramResultDate  === selectedDay){
                            pHasRecordedJourney = true;
                            journeyIndex = e;
                        }                     
                    }
                    
                    if(dayPrograms[0][i].recorded_journeys.length > 0 && pHasRecordedJourney === true){
                        content.push(<tr key={"tr" + i}>
                                        <td><StickyNoteProgram showVideo={showVideo} addVideo={addVideo} deleteVideo={deleteVideo} formattedDateS={formattedDateS} note={dayPrograms[0][i]} key={dayPrograms[0][i].id} saveEdits={saveEdits} deleteProgram={deleteProgram} /></td>
                                        <td><StickyNoteResults results={resultText} note={dayPrograms[0][i]} key={"note"+dayPrograms[0][i].id+selectedDay} saveResults={saveResults} /></td>
                                        <td><StickyNoteMap note={dayPrograms[0][i]} jindex = {journeyIndex} key={"maps"+dayPrograms[0][i].id+selectedDay} /></td>
                                    </tr>);
                    }
                    else{
                        content.push(<tr key={"tr" + i}>
                                        <td><StickyNoteProgram showVideo={showVideo} addVideo={addVideo} deleteVideo={deleteVideo} formattedDateS={formattedDateS} note={dayPrograms[0][i]} key={dayPrograms[0][i].id} saveEdits={saveEdits} deleteProgram={deleteProgram} /></td>
                                        <td><StickyNoteResults results={resultText} note={dayPrograms[0][i]} key={"note"+dayPrograms[0][i].id+selectedDay} saveResults={saveResults} /></td>
                                    </tr>);
                    }
                }
            }

            return <>{content}</>;
        }
        else{

            return(
                <></>
                );
        }
    };

    const years = [];
    const lajit = [];
    const date = new Date();
    const curY = date.getFullYear();
    for(let i = curY;i>=(curY-5);i--){
        years.push(<option value={i} key={i}>{i}</option>);
    }

    const weeknumbers = []; 
    for (let i = 1; i <= 52; i++) {
        weeknumbers.push(<option value={i} ref={el => refs.current[i] = el} key={"wn"+i}>{i}</option>);
    }

    const weekdays = []; 
        
    weekdays.push(<option value={1} ref={el => refsdays.current[0] = el} key={"drowma"}>Maanantai</option>);
    weekdays.push(<option value={2} ref={el => refsdays.current[1] = el} key={"drowti"}>Tiistai</option>);
    weekdays.push(<option value={3} ref={el => refsdays.current[2] = el} key={"drowke"}>Keskiviikko</option>);
    weekdays.push(<option value={4} ref={el => refsdays.current[3] = el} key={"drowto"}>Torstai</option>);
    weekdays.push(<option value={5} ref={el => refsdays.current[4] = el} key={"drowpe"}>Perjantai</option>);
    weekdays.push(<option value={6} ref={el => refsdays.current[5] = el} key={"drowla"}>Lauantai</option>);
    weekdays.push(<option value={0} ref={el => refsdays.current[6] = el} key={"drowsu"}>Sunnuntai</option>);

    lajit.push(<option value={99} ref={el => refslajit.current[99] = el} key={"kaikki"}>Kaikki</option>);
    lajit.push(<option value={4} ref={el => refslajit.current[4] = el} key={"Kuntosali"}>Kuntosali</option>);
    lajit.push(<option value={1} ref={el => refslajit.current[1] = el} key={"Juoksu"}>Juoksu</option>);
    lajit.push(<option value={2} ref={el => refslajit.current[2] = el} key={"Pyöräily"}>Pyöräily</option>);
    lajit.push(<option value={3} ref={el => refslajit.current[3] = el} key={"Hiihto"}>Hiihto</option>);
    lajit.push(<option value={5} ref={el => refslajit.current[5] = el} key={"Venyttely"}>Venyttely</option>);
    lajit.push(<option value={6} ref={el => refslajit.current[6] = el} key={"Muu"}>Muu</option>);
    
    var youtubeLink = ""; 
    if(showYoutube !== false){
        if(showYoutube.includes("https:")){
            var basicAddress = showYoutube.split("?v=");
            var getID = basicAddress[1].split("&");
            if(getID.length > 0) youtubeLink = "https://www.youtube.com/embed/" + getID[0];
            else youtubeLink = "https://www.youtube.com/embed/" + basicAddress[1];
        } 
        else youtubeLink = "https://www.youtube.com/embed/" + showYoutube;
    }

    return(
        <>
            <div id="mainb" className="mainBody">
                <div>
                    <div className="mainBodyHeader">
                        <h2>Tulokset</h2>
                    </div>		
                </div>

                <div id="addR">
                    <div>

                        <label className="wLabelLeft wLabelRight">Laji:</label>
                        <select defaultValue={0} className=""  onChange={changeLaji}>
                            {lajit}
                        </select>

                        <label className="wLabelLeft wLabelRight">Vuosi:</label>
                        <select defaultValue={curY} className=""  onChange={changeYear}>
                            {years}
                        </select>				
                        
                        <label className="wLabelLeft wLabelRight">Viikko:</label>
                        <select defaultValue={week} onChange={changeWeek}>
                            {weeknumbers}
                        </select>
                        
                        <label className="wLabelLeft wLabelRight">Viikonpäivä:</label>
                        <select defaultValue={day} className="wSelectRight" onChange={changeDay}>
                            {weekdays}
                        </select>
                      
                    </div>
                </div>

                <div className="progsdiv">
                    
                    <div className="programcontainer" clickable="true">
                        <table>
                            <thead>
                              
                            </thead>
                            <tbody>
                                {programs()}
                                  
                            </tbody>
                        </table>
                      
                    </div>
                </div>

                <div>
                    <div ref={showyoutubeDiv} className="modalDialog modalYouTube">
                    {showYoutube &&                      
                             <div>
                                <a href="/#" title="Sulje" onClick={hideYoutube} className="close">X</a>
                                <YoutubeVideo embedId={youtubeLink} />
                            </div>
                    }
                    </div>
                </div>   

                <div>
                    <div ref={showyoutubeAddDiv} className="modalDialog">                    
                        <div>
                            <a href="/#" title="Sulje"  onClick={hideYoutubeAdd} className="close">X</a>

                            <h2>Lisää YouTube video ohjelmaan</h2>

                                <form>
                                    <span ref={youtubeIDSpan}>Syötä YouTube videon ID:</span>
                                    <p>
                                        <input ref={youtubeIDinInput} type="text" placeholder="Videon osoite tai ID" className="field"/>
                                        
                                    </p>
                                    <button onClick={handleVideoSubmit}>Lisää video</button>
                                </form>
                        </div>
                    </div>
                </div>

            </div>
        </>

    )
    
}

export default ProgramResults; //Jotta app osaa importtaa tämän